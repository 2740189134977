<template>
<div id="post-gallery-slider" class="carousel slide carousel-dark" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div :class="getClasses(image, index)" v-for="(image, index) in images" :key="index">
      <img :src="getImageUrl(image)" class="d-block w-100">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#post-gallery-slider" data-bs-slide="prev">
		<span class="carousel-control-prev-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Zurück</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#post-gallery-slider" data-bs-slide="next">
		<span class="carousel-control-next-icon" aria-hidden="true"></span>
		<span class="visually-hidden">Vorwärts</span>
  </button>
</div>
</template>
<script>
export default {
	props: ["images"],
	data() {
		return {
		}
	},
	methods: {
		getImageUrl(image)
		{
			return (image.directus_files_id) ? `https://api.bs-rt.de/assets/${image.directus_files_id}?key=gallery-slider` : "";
		},
		getClasses(image, index)
		{
			return (index === 0) ? "carousel-item active" : "carousel-item";
		}
	}
}
</script>
<style scoped>
.carousel-control-prev {
	opacity: 0;
}
.carousel-control-next {
	opacity: 0;
}
</style>
