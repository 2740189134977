<template>
	<section class="parallax effect-section" :style="getImage1">
		<div class="mask bg-black opacity-6"></div>
		<div class="container position-relative">
			<div class="row justify-content-center pb-8 min-vh-50 align-items-center">
				<div class="col-lg-10 text-center">
					<h6 class="text-white fw-light"><a class="text-reset" v-text="getType"></a></h6>
					<h1 class="text-white m-0 fw-light" v-if="post" v-text="post.title" />
					<div class="nav white-link text-white pt-3 small justify-content-center">
						<span class="mb-2 me-4"><i class="fad fa-clock me-2"></i> {{ getDate }}</span>
						<span class="mb-2 me-4"><i class="fad fa-user me-2"></i> 
							<router-link v-if="post" :to="{ name: 'Mitglied', params: { id: post.creator.id } }" v-text="post.creator.name" class="text-white" />
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<div class="pb-5 pb-lg-10 single-post-type-03">
		<div class="container position-relative mt-n8">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<div class="card">
						<div class="card-body">
							<article v-if="post" v-html="post.text" />
							<PostGallery :images="post.gallery.files" v-if="post && post.gallery" />
						</div>
						<div class="nav tag-style p-3" v-if="post && post.tags">
							<a v-for="tag in post.tags" :key="tag" v-text="tag"></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
import moment from 'moment'
import PostGallery from '@/views/PostGallery.vue'
export default {
	props: ['post'],
	components: {
		PostGallery
	},
	computed: {
		getDate() {
			return (this.post && this.post.date_created) ? moment(this.post.date_created).format('LL') : 0;
		},
		getType() {
			if(this.post) {
				switch(this.post.category)
				{
					case "review":
						return "Rückblick";
					case "news":
						return "Neuigkeiten";
					case "event":
						return "Veranstaltung";
					default:
						return "Beitrag";
				}
			}
			else
			{
				return null;
			}
		},
		getImage1() {
			return (this.post && this.post.picture) ? `background-image: url(https://api.bs-rt.de/assets/${this.post.picture.id}?key=cover); background-repeat: no-repeat; background-size: cover; background-position: center;` : 'null';
		}
	},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>