<template>
	<PostFull :post="post" />
</template>
<script>
import PostFull from '@/views/PostFull.vue'
export default {
	name: 'Beitrag',
	props: ['id'],
	components: {
		PostFull,
	},
	computed: {
		post() {
			return this.$store.state.posts.find(q => q.id == this.id);
		},
		error() {
			return this.$store.state.loadingError;
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	},
	created() {
		this.$store.dispatch('loadPosts');
	},
};
</script>